<template>
  <div>
    <b-card title="Seja bem vindo ao CAS 🚀">
      <b-card-text>Se você está aqui é porque trabalha com a GOTECH.</b-card-text>
      <b-card-text>O CAS é o Cassino Administrative System desenvolvido e administrado por GOTECH.</b-card-text>
      <b-card-text>Navegue pelos menus na lateral esquerda e acesse as diversas áreas do sistema.</b-card-text>
    </b-card>

    <div class="py-5">
      <b-card-text class="text-center display-5"><span> 🍀🤩🎉</span>  Boa sorte em sua jornada!  <span>🎉🤩🍀</span></b-card-text>
      <b-button
        type="submit"
        variant="primary"
        block
        class="col-12 mt-md-3 col-md-2 offset-md-5"
        @click="$router.push('/login')"
        v-if="!isUserLoggedIn()"
      >
        Logar com LDAP
      </b-button>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BButton} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
  },
  methods:{
    isUserLoggedIn(){
      return !(localStorage.getItem('userData') == null)
    }
  }
}
</script>

<style>
.display-5{
  font-size: 1.5rem;
}
</style>
